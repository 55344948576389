<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Artikeldatenbank">
      <template #actions>
        <el-input
          v-model="computedSearchQuery"
          placeholder="Durchsuchen…"
          clearable
        >
        </el-input>
      </template>

      <data-loader
        ref="dataloader"
        :endpoint="
          `/admin/shared_articles?page=${currentPage}&query=${remoteSearchQuery}`
        "
      >
        <template #loaded="{data}">
          <el-table
            style="width: 100%;"
            empty-text="Keine Ergebnisse"
            stripe
            :data="data.articles"
          >
            <el-table-column prop="title" label="Titel">
              <template #default="scope">
                {{ scope.row.title }}
                <el-tag
                  v-if="scope.row.num_forks > 5"
                  size="mini"
                  type="success"
                  >Beliebt</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column prop="region_name" label="Region/Autor">
              <template #default="scope">
                <el-tag
                  v-if="scope.row.region_name === 'Musterregion'"
                  size="small"
                  type="info"
                >
                  Studio Nomai
                </el-tag>
                <span v-else>{{ scope.row.region_name }}</span>
                <el-popover placement="top" trigger="hover">
                  <div>
                    <b>{{ scope.row.state_name }}</b>
                  </div>
                  <div>{{ scope.row.num_forks }} mal übernommen</div>
                  <span slot="reference" style="margin-left: 6px;">
                    <i class="fa-info-square fal"></i>
                  </span>
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column prop="updated_at" label="Zuletzt aktualisiert">
              <template #default="scope">
                {{ format(parseISO(scope.row.updated_at), "dd.LL.yyyy") }}
              </template>
            </el-table-column>

            <el-table-column label="Aktionen" :width="isSuperAdmin ? 550 : 450">
              <template #default="scope">
                <div class="action-buttons">
                  <el-popover placement="right" width="600" trigger="click">
                    <p
                      style="font-size: 16px; line-height: 1.5; text-align: left; word-break: normal; padding: 0px 15px 0px 15px;"
                    >
                      {{ scope.row.summary }}
                      <br /><i style="color: orange; font-size: 12px;">
                        Dies ist eine automatisch generierte Zusammenfassung</i
                      >
                    </p>

                    <el-button
                      slot="reference"
                      size="mini"
                      :disabled="!scope.row.summary"
                      >Zusammenfassung</el-button
                    >
                  </el-popover>

                  <el-button
                    style="margin-left: 10px;"
                    icon="fal fa-eye"
                    size="mini"
                    @click="previewArticle(scope.row.article_url)"
                  >
                    Ansehen
                  </el-button>

                  <el-button
                    icon="fal fa-plus"
                    type="primary"
                    size="mini"
                    @click="addArticle(scope.row)"
                  >
                    Übernehmen
                  </el-button>

                  <el-button
                    v-if="isSuperAdmin"
                    icon="fal fa-edit"
                    size="mini"
                    @click="editArticle(scope.row)"
                  >
                    Bearbeiten
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <the-pagination
            v-model="currentPage"
            :total-count="data.total_count"
          ></the-pagination>
        </template>
      </data-loader>
    </page-content>

    <el-drawer
      title="Artikel aus Datenbank übernehmen"
      :visible.sync="drawerVisible"
      direction="rtl"
    >
      <div class="drawer--content">
        <el-form ref="form" label-position="top">
          <el-form-item label="Ihre Artikelsammlungen">
            <el-cascader
              v-model="articleCollectionIds"
              :options="articleCategoriesForCascader"
              :props="{ multiple: true, checkStrictly: true, emitPath: false }"
              placeholder="Artikelsammlungen auswählen"
              :clearable="false"
            ></el-cascader>
          </el-form-item>

          <el-button
            v-if="!forkedArticle"
            type="primary"
            :loading="forkingArticle"
            @click="forkArticle"
          >
            Artikel übernehmen
          </el-button>
          <div v-else>
            <el-button @click="drawerVisible = false">
              Schließen
            </el-button>
            <el-button type="primary" @click="showArticleCollection">
              Zum Artikel gehen
            </el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as log from "loglevel"
import { debounce } from "lodash-es"
import { format, parseISO } from "date-fns"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import ArticlesRepository from "@/repositories/articles_repository.js"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import DataLoader from "@/components/DataLoader"
import ThePagination from "@/components/ThePagination"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Artikeldatenbank"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    DataLoader,
    ThePagination
  },
  mixins: [ApiErrorHandlerMixin],
  beforeRouteUpdate(to, from, next) {
    log.info("view: beforeRouteUpdate")

    if (from.name === to.name) {
      if (to.query.page) {
        log.info(`setting current page to ${to.query.page}`)
        this.currentPage = parseInt(to.query.page)
      } else {
        log.info(`setting current page to 1`)
        this.currentPage = 1
      }

      if (to.query.query) {
        log.info(`setting query to ${to.query.query}`)
        this.localSearchQuery = to.query.query
        this.remoteSearchQuery = to.query.query
      } else {
        log.info(`setting query to ""`)
        this.localSearchQuery = ""
        this.remoteSearchQuery = ""
      }
    }
    next()
  },
  data() {
    return {
      format,
      parseISO,
      currentPage: parseInt(this.$route.query.page) || 1,
      localSearchQuery: this.$route.query.query || "",
      remoteSearchQuery: this.$route.query.query || "",
      drawerVisible: false,
      articleCollectionIds: [0],
      articleCategories: [],
      forkingArticle: false,
      forkedArticle: false,
      articleToFork: null
    }
  },
  computed: {
    ...mapGetters("auth", ["userData", "isSuperAdmin"]),
    computedSearchQuery: {
      get() {
        return this.localSearchQuery
      },
      set(value) {
        this.localSearchQuery = value
        this.debouncedSearch(value)
      }
    },
    articleCategoriesForCascader() {
      return this.parseGuideItemTree(this.articleCategories)
    }
  },
  created() {
    GuideItemsRepository.getAll()
      .then(categories => {
        this.articleCategories = categories
      })
      .catch(error => {
        this.handleApiError(
          error,
          "Fehler beim Laden der Wissenswertes Elemente"
        )
      })
  },
  methods: {
    previewArticle(url) {
      window.open(url)
    },
    addArticle(article) {
      if (parseInt(this.userData.region_id) == parseInt(article.region_id)) {
        alert("Sie können keine Artikel aus der eigenen Region übernehmen.")
      } else {
        this.articleCollectionIds = [0]
        this.forkedArticle = false
        this.articleToFork = article.id
        this.drawerVisible = true
      }
    },
    editArticle(article) {
      window.open(
        this.$router.resolve({
          name: "EditArticlePage",
          query: {
            region_id: article.region_id ? article.region_id : null,
            article_collection_name: article.collection_name
              ? article.collection_name
              : null,
            article_collection_id: article.collection_id
              ? article.collection_id
              : null,
            redirect: this.$route.query.redirect || this.$route.fullPath
          },
          params: {
            id: article.id
          }
        }).href,
        "_blank"
      )
    },
    debouncedSearch: debounce(
      async function(query) {
        log.info(`Searching for "${query}"…`)
        this.remoteSearchQuery = query
        this.currentPage = 1
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: 1,
            query: query
          }
        })
      },
      300,
      { maxWait: 3000 }
    ),
    parseGuideItemTree(categories) {
      let items = []
      let that = this
      categories.forEach(function(cat) {
        if (cat.type == "folder" || cat.type === "articles") {
          let item = {
            label: cat.name,
            value: cat.collection_id,
            disabled: cat.type === "folder",
            children: that.parseGuideItemTree(cat.children)
          }
          items.push(item)
        }
      })
      return items
    },
    async forkArticle() {
      if (
        this.articleCollectionIds.length > 0 &&
        this.articleCollectionIds[0] != 0
      ) {
        this.forkingArticle = true
        try {
          await ArticlesRepository.fork(
            this.articleToFork,
            this.articleCollectionIds
          )
          this.forkingArticle = false
          this.forkedArticle = true
          this.$message({
            message: `Artikel wurde erfolgreich gespeichert`,
            type: "success",
            showClose: true
          })
        } catch (error) {
          this.forkingArticle = false
          this.handleApiError(error)
        }
      } else {
        this.$message({
          message: `Sie müssen mindestens eine Artikelsammlung wählen`,
          type: "error",
          showClose: true
        })
      }
    },
    showArticleCollection() {
      this.$router.push({
        name: "ShowArticleCollectionPage",
        params: { id: this.articleCollectionIds[0] }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer--content {
  padding: 0px 20px 20px 20px;
}

.action-buttons {
  display: flex;
  align-items: center;
}
</style>
